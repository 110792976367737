@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  font-size: 1.481481481vh;
}

.rotate-full {
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.rotate-90 {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-minus-90 {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media screen and (max-aspect-ratio: 16/9) and (min-width: 1024px) {
  html {
    font-size: 0.8333333333333vw;
  }
}

.datepicker-input-whitemode::-webkit-calendar-picker-indicator {
  filter: invert(0);
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.not-scroll {
  overflow: hidden;
}

.font-size-16 {
  font-size: 16px !important;
}

.app-container {
  padding: 5vh 18.75vw;
}

.app-container-with-sidebar {
  padding: 1.5rem;
}

body {
  font-family: 'Poppins', 'sans-serif';
  color: #ffff;
}

.theme-dark-background-color {
  background-color: #202020;
}

.theme-iframe-background-color {
  background-color: #ffffff;
}

.theme-light-background-color {
  background-color: #eeeef1;
}

/* ++++++++++++++++++++CHANGE COLOR DATE PICKER INPUT DATE ++++++++++++++++++++++ */

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

/* ++++++++++++++++++++ BUTTON PASSWORD FORGOTTEN ++++++++++++++++++++++ */
.sb-container-forgotten-password-and-hide > button {
  margin-right: 0.625rem;
}
.sb-forgotten-password {
  font-size: 0.75rem !important;
}

/* ++++++++++++++++++++ SCROLL Personnalisation ++++++++++++++++++++++ */
.scroll_light_mode::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

.scroll_light_mode_search_in_list::-webkit-scrollbar-track {
  background-color: #f1f2f5;
}

.scroll_light_mode_search_in_list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: white;
}

.scroll_light_mode::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #dfdfdf;
}

.scroll_light_mode::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #8f8f8f;
}

.scroll_light_mode::-webkit-scrollbar-corner {
  background: white;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(49, 48, 48);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(49, 48, 48);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(100, 100, 100);
}

::-webkit-scrollbar-corner {
  background: rgb(49, 48, 48);
}

.scrollWidthMini::-webkit-scrollbar {
  width: 0px;
}

/* Corrige le background blanc lors de l\'auto-complétion par google chrome */
input.dark-mode-input:-webkit-autofill,
input.dark-mode-input:-webkit-autofill:hover,
input.dark-mode-input:-webkit-autofill:focus,
input.dark-mode-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #191919 inset !important;
}

input.dark-mode-input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
.adress-container > div > input {
  display: none;
}

input.light-mode-input:-webkit-autofill,
input.light-mode-input:-webkit-autofill:hover,
input.light-mode-input:-webkit-autofill:focus,
input.light-mode-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #c6c6c6 inset !important;
}

input.light-mode-input-white:-webkit-autofill,
input.light-mode-input-white:-webkit-autofill:hover,
input.light-mode-input-white:-webkit-autofill:focus,
input.light-mode-input-white:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #ffffff inset !important;
}

input.dark-mode-input-folder:-webkit-autofill,
input.dark-mode-input-folder:-webkit-autofill:hover,
input.dark-mode-input-folder:-webkit-autofill:focus,
input.dark-mode-input-folder:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #202020 inset !important;
}

input.dark-mode-input-folder:-webkit-autofill {
  -webkit-text-fill-color: #ffffff !important;
}

input.light-mode-input:-webkit-autofill,
input.light-mode-input:-webkit-autofill:hover,
input.light-mode-input:-webkit-autofill:focus,
input.light-mode-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #f1f2f5 inset !important;
}

input.light-mode-input:-webkit-autofill {
  -webkit-text-fill-color: #191919 !important;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}
/* ++++++++++++++++++++ LOADER DASHBOARD ++++++++++++++++++++++ */

.load {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.load div {
  background-color: #cecece;
  margin-left: 0.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  animation-name: loading;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.load-two {
  animation-delay: 0.4s;
}
.load-three {
  animation-delay: 0.8s;
}
@keyframes loading {
  to {
    opacity: 0.3;
    transform: translateY(-1.5rem);
    -webkit-transform: translateY(-1.5rem);
    -moz-transform: translateY(-1.5rem);
    -ms-transform: translateY(-1.5rem);
    -o-transform: translateY(-1.5rem);
  }
}

.load-dashboard div {
  width: 1.5rem;
  height: 1.5rem;
}

.load-dashboard {
  height: calc(100vh - 48px) !important;
}

.load-folder div {
  width: 1.5rem;
  height: 1.5rem;
}

.pdfPage > canvas {
  max-width: 100%;
  height: auto !important;
}

/* ++++++++++++++++++++ LOADER IMPORT ++++++++++++++++++++++ */

.load-import div {
  width: 1.25rem;
  height: 1.25rem;
}

/* ++++++++++++++++++++ LOADER BAR ++++++++++++++++++++++ */

.loaderBar {
  height: 5px;
  position: relative;
  border-radius: 10px;
  background-color: transparent;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

.sb-text-progress-bar {
  padding-bottom: 0.625rem;
}
.sb-check-card > div {
  width: 95%;
}
/* ++++++++++++++++++++ Animation NextStepsContent ++++++++++++++++++++++ */

.animation-content-dropDown {
  animation-duration: 1s;
  animation-name: slidein;
}

.animation-content-dropDown-mobile {
  animation-duration: 1s;
  animation-name: slidedown;
}

.animation-content {
  animation-duration: 1s;
  animation-name: slideinout;
}

.animation-dropDown {
  animation-duration: 1s;
  animation-name: slidedown;
}

.animation-disc {
  animation-duration: 1s;
  animation-name: disc;
}

@keyframes disc {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slidedown {
  from {
    margin-top: -10%;
    opacity: 0;
  }

  to {
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes slideup {
  from {
    margin-top: -2%;
    opacity: 0;
  }

  to {
    margin-top: -9%;
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    margin-left: -60%;
    margin-right: 60%;
    opacity: 0;
  }

  to {
    margin-left: 0%;
    margin-right: 0%;
    opacity: 1;
  }
}

@keyframes slideinout {
  0% {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    width: 100%;
  }

  40% {
    margin-left: 100%;
    opacity: 0;
    width: 100%;
  }

  45% {
    margin-left: -40%;
    margin-right: 40%;
    opacity: 0;
    width: 100%;
  }

  100% {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    width: 100%;
  }
}

.Toastify__progress-bar-theme--dark {
  background-color: #5d6ad2 !important;
}

/* ++++++++++++++++++++STORYBOOOK AMELIORATIONS++++++++++++++++++++++ */

/* modif pour heightSelect */
.sb-height-select-content > div > div > input {
  padding-top: 0 !important;
}

/*rajout padding left checkCard w/ image */
.sb-check-card-checkbox {
  padding-left: 10px;
}

/* Amélioration style map storybook */
#map {
  width: 100% !important;
  height: 100% !important;
  border-radius: 16px !important;
  -webkit-border-radius: 16px !important;
  -moz-border-radius: 16px !important;
  -ms-border-radius: 16px !important;
  -o-border-radius: 16px !important;
}
/* Amélioration style map storybook */
#map > div {
  background-color: black !important;
  position: absolute !important;
}
div > a > div {
  display: none;
}

/* Amélioration InputSearchList storybook */
.sb-input-search-list-button {
  padding-top: 0px !important;
  cursor: pointer !important;
}
.sb-input-search-list-button:hover {
  opacity: 0.8 !important;
}

.sb-container-input-search-list {
  height: 5rem !important;
  padding: 1rem 2.25rem !important;
}

/*Amélioration inputSmall storybook*/
.height-input-small {
  height: 65px !important;
}
.height-input-content-small > input,
.label-input-small {
  font-size: 0.875rem !important;
}

/* Amélioration warning notification storybook */
.sb-warning-notification {
  padding: 1.25rem 1.25rem !important;
}
.sb-wraning-notification-content > p {
  padding-bottom: 0.25rem;
}

/* Amélioration style buttons storybook */
.btn-arrow {
  padding: 0.875rem;
}
.btnSbContainer > .sb-button-arrow-small {
  padding: 0.625rem 0.625rem !important;
  width: 50% !important;
}
.sb-check-card-square {
  height: 100% !important;
}
.btn-sb-w-full {
  width: 100% !important;
}
.sb-btn-arrow,
.sb-btn-arrow-color,
.sb-button-arrow-small,
.sb-button-arrow-small-color {
  justify-content: space-between !important;
}

.sb-btn-arrow {
  width: 100% !important;
}

/* modif input size label dans dimensioning note*/

/* Animation loader dashboard */

@keyframes a {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.animLoaderDash {
  animation: jump 0.5s linear infinite;
}

.a {
  animation: a 1s linear infinite;
}
.b {
  animation: b 2s linear infinite;
}
.c {
  animation: a 1s linear infinite;
}
.d {
  animation: b 2s linear infinite;
}
.e {
  animation: a 1s linear infinite;
}

.animLoaderDash {
  animation: jump 0.5s linear infinite;
}

@keyframes b {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* fix mauvaise class storybook dans le InputSearchList */
.line-height-24 {
  line-height: 1.5rem !important;
}

/* animation pour chevron homeLeftCard dans dashboard */
.mouseActive {
  transform: rotate(180deg);
  transition: transform 700ms ease;
}

.mouseNotActive {
  transform: rotate(360deg);
  transition: transform 700ms ease;
}

/* CHECKBOX CUSTON LIST */
.input-checked[type='checkbox'] {
  opacity: 0;
}

/* MODIFS POUR TOOLBOX */
.dropdownToolBox > button {
  background-color: #1c1e1f !important;
  border-radius: 8px !important;
  border: 1px solid rgb(255, 255, 255, 0.5);
  width: 100% !important;
}

.dropdownToolBox > div {
  padding: 0.625rem 0 !important;
}

.input-search-custom > .sb-input-button {
  width: 1.875rem !important;
  height: 1.875rem !important;
  background-color: transparent;
}

.input-search-custom > input {
  background-color: #3d3d3d !important;
  border-radius: 8px !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
}
/* CUSTOMIZE CHECKBOX DASHBOARD */
.check-box {
  --checked-color: #5d6ad2;
  --unchecked-color: #aaa;
}

.check-box__switcher {
  background-color: var(--unchecked-color);
  background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0"%3F%3E%3Csvg width="20" height="20" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"%3E%3Ccircle cx="10" cy="10" fill="%23ffffff" r="10"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-size: auto calc(100% - 0.25rem);
  background-position: center left 0.125rem;
  transition: background-position 0.2s ease-in, background-color 0s 0.1s;
}

.check-box__switcher:checked {
  background-position: center right 0.125rem;
  background-color: var(--checked-color);
}

.check-box__switcher:disabled:checked {
  background-position: center right 0.125rem;
  background-color: var(--checked-disabled-color);
  cursor: pointer;
}

.check-box__switcher:disabled {
  background-position: center left 0.125rem;
  background-color: var(--unchecked-disabled-color);
  cursor: pointer;
}

/* ++++++++++++++++++++ BULKIMPORT++++++++++++++++++++++ */
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 50%;
  animation: spin 2s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  right: 20px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.anim-loading-imported-file {
  /*animation: gradient 8s ease-out infinite;*/
  transition: width 1.5s linear;
}
@keyframes gradient {
  0% {
    width: 0;
  }

  50% {
    width: 70%;
  }

  100% {
    width: 200%;
  }
}
.btn-bulkImport {
  height: 3.125rem !important;
  padding: 0.75rem 1.5rem !important;
}

.bg-color-document-facultatif {
  background-color: #191919 !important;
}

.bg-color-document-obligatoire {
  background-color: transparent !important;
}

.sb-isloading {
  bottom: 25% !important;
}

.toolbox-material-input-search input {
  font-size: 0.75rem !important;
  padding-left: 1rem !important;
  padding-right: 3rem !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.toolbox-material-input-search svg {
  width: 1.125rem !important;
}

@media (max-width: 769px) {
  /* fix warning InputSearchList storybook */
  .sb-container-input-search-list {
    height: 3.125rem !important;
    padding: 1rem 2.125rem !important;
  }

  .sb-check-card-square {
    height: 100% !important;
  }
  .sb-container-animate-cadastre {
    padding-bottom: 0.875rem !important;
  }

  .sb-forgotten-password {
    font-size: 0.875rem !important;
  }
  /* fix height svg storybook */
  .sb-check-card-square-img {
    height: 10rem !important;
    width: 10rem !important;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  .app-container {
    padding: 2vh 5vw;
  }

  .sb-forgotten-password {
    font-size: 0.875rem !important;
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
}

@media (max-width: 1800px) {
  .app-container-with-sidebar {
    padding: 0.5rem;
  }

  .load-dashboard {
    height: calc(100vh - 16px) !important;
  }
}
